'use strict';

import $ from 'jquery';
import 'jquery.easing';

$(function () {
  // SP Menu
  $('.js-spMenuButton').click(function () {
    $(this).toggleClass('is-active');
    $('.js-spMenuOvarlay').toggleClass('is-show');
  });

  // information
  if($('.js-topInfo')[0]){
    $.get('./information/information/frontlist', function(data){
      $('.js-topInfo').children().remove();
      $('.js-topInfo').html(data);
    });
  }

  // Smooth Scroll
  $(window).scroll(function() {
    if ($(this).scrollTop() > 50) {
      $('.js-header').addClass('is-fixed');
    }
  });

  $('a[href^="#"]').click(function(){
    var headerHeight = 0;
    var mql = window.matchMedia('screen and (max-width: 767px)');
    if (mql.matches) {
      headerHeight = 50;
    }
    var speed = 800;
    var href= $(this).attr('href');
    var target = $(href == '#' || href == '' ? 'html' : href);
    var position = target.offset().top-headerHeight;
    $('html, body').animate({scrollTop:position}, speed, 'easeInOutExpo');
    return false;
  });
});
